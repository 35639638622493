<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header
                    title="Typography"
                >
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Typography Rules and CSS Styles" class="mt-4 mb-6">
            <div class="mb-6 mx-6 pb-6">
                <hb-data-table-header
                    left-columns="12"
                    right-columns="0"
                >
                    <template v-slot:description>
                        The type family we use inside Hummingbird is known as Graphik Web, which can be downloaded here: <hb-link color="secondary" href="https://drive.google.com/drive/folders/134VqCqMKsl91hwxC8X_yUG9-ju5Md6-D?usp=sharing" target="_blank">Graphik Web</hb-link>
                        <br />
                        <span class="font-weight-medium pr-1">IMPORTANT:</span>You must add required global CSS classes/names and rules for this typography chart as outlined here:
                        <hb-link class="ml-1" :to="'/design-system/global-css'"
                        >Go to Global CSS Page</hb-link
                        >
                        <br />
                    </template>
                </hb-data-table-header>
                <hb-data-table
                    :headers="headers"
                    :items="items"
                >
                    <template v-slot:item.name="{ item }">
                        <span :class="{'font-weight-medium' : item.weight === '500'}" :style="'font-size:' + item.size + ';line-height:' + item.height">{{ item.name }}</span>
                    </template>
                </hb-data-table>
            </div>
        </hb-card>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemTypography",
        data: function() {
            return {
                headers: [
                    { text: 'Name', value: 'name' },
                    { text: 'CSS Class', value: 'class' },
                    { text: 'Font Size', value: 'size' },
                    { text: 'Line Height', value: 'height' },
                    { text: 'Weight', value: 'weight' },
                ],
                items: [
                    { name: 'Header 1', class: 'hb-font-header-1', size: '28px ', height: '32px', weight: '500' },
                    { name: 'Header 2 - Medium', class: 'hb-font-header-2-medium', size: '24px ', height: '32px', weight: '500' },
                    { name: 'Header 2 - Regular', class: 'hb-font-header-2', size: '24px ', height: '32px', weight: 'regular' },
                    { name: 'Header 3 - Medium', class: 'hb-font-header-3-medium', size: '16px ', height: '24px', weight: '500' },
                    { name: 'Header 3 - Regular', class: 'hb-font-header-3', size: '16px ', height: '24px', weight: 'regular' },
                    { name: 'Body - Medium', class: 'hb-font-body-medium', size: '14px ', height: '24px', weight: '500' },
                    { name: 'Body - Regular', class: 'hb-font-body', size: '14px ', height: '24px', weight: 'regular' },
                    { name: 'Caption - Medium', class: 'hb-font-caption-medium', size: '12px ', height: '16px', weight: '500' },
                    { name: 'Caption - Regular', class: 'hb-font-caption', size: '12px ', height: '16px', weight: 'regular' },
                ],
            };
        },
    }
</script>

<style scoped>

</style>
